<template>
  <div class="detail">
    <detail-header title="成果详情" />
    <div class="detail_main page_width">
      <div class="main_content">
        <info-item
          :label-width="labelWidth"
          label="成果名称"
          :value="form.title">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="成果类型"
          :value="form.achievementType">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="成果第一完成人"
          :value="form.firstCompletedBy">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="成果持有单位"
          :value="form.holdUnit">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="所在区域"
          :value="form.region">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="产业特长"
          :value="getIndustrySectorNames(form.industries)">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="技术领域"
          :value="getTechnicalNames(form.technical)">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="成熟度"
          :value="form.maturity">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="成果描述"
          :value="form.description">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="合作方式"
          :value="form.cooperationMode ? form.cooperationMode.toString() : ''">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="交易价格"
          :value="form.price">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="成果资料"
          value-type="file-list"
          :value="form.attachments || []">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="联系人"
          :value="form.contactName">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="联系电话"
          :value="form.contactNumber">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="联系邮箱"
          :value="form.contactEmail">
        </info-item>
        <info-item
          :label-width="labelWidth"
          label="是否为成果代理人"
          :value="{
            1: '是',
            0: '否',
          }[form.proxyAgent]">
        </info-item>
        <template v-if="form.proxyAgent === 1">
          <info-item
            :label-width="labelWidth"
            label="代理人单位"
            :value="form.proxyUnit">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="上传代理协议"
            value-type="file-list"
            :value="form.agentAgreement || []">
          </info-item>
        </template>
        <template v-if="form.proxyAgent === 0">
          <info-item
            :label-width="labelWidth"
            label="是否委托平台服务商推广"
            :value="{
              1: '是',
              0: '否',
            }[form.isEntrust]">
          </info-item>
        </template>
        <!-- 驳回状态下显示驳回原因 -->
        <template v-if="globalVar.ACHIEVEMENT_STATUS_2">
          <info-item
            :label-width="labelWidth"
            label="驳回原因"
            :value="form.reason">
          </info-item>
          <!-- 已驳回状态下显示重新提交按钮 -->
          <el-row>
            <el-button
              type="primary"
              size="small"
              @click="onCreate">
              重新发布
            </el-button>
          </el-row>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
export default {
  data () {
    return {
      loading: false,
      labelWidth: '180px',
      form: {}
    }
  },
  computed: {
    // 成果Id
    id () {
      return this.$route.params.id || ''
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    },
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    },
    // 拿到技术领域的id字符串
    getTechnicalNames () {
      return (technical = []) => {
        let result = ''

        this.technicalList.forEach(item => {
          if (technical.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    },
    // 拿到行业领域的id字符串
    getIndustrySectorNames () {
      return (idList = []) => {
        let result = ''

        this.industrySectorList.forEach(item => {
          if (idList.includes(item.id)) {
            result += result === '' ? item.name : ',' + item.name
          }
        })
        return result
      }
    }
  },
  created () {
    // 获取成果详情
    this.getAchievementDetail()
  },
  methods: {
    // 获取成果详情
    getAchievementDetail () {
      this.loading = true
      api.getAchievementDetail(this.id).then(res => {
        if (res.data.code === 0) {
          this.form = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 发布需求
    onCreate () {
      window.open('/achievement-create')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;

    }
  }
}
</style>
